<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Siamo un ente di formazione professionale. Da noi troverai competenza e professionalità. Il nostro obiettivo è fornire una formazione con alti standard di qualità che permetta ai nostri discenti di realizzare i propri obiettivi personali. La nostra idea è che la formazione è un incontro unico di persone.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/egeriaformazione/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/egeriaformazione/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://egeria.discentya.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://egeria.discentya.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Verdi n. 58<br>81031 - Aversa (CE) - Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+393403525017">Telefono: (+39) 340-3525017</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:egeria.formazione@gmail.com">egeria.formazione@gmail.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+393403525017">Lunedì-Venerdì: 09:00÷19:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2023 Egeria Formazione - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a class="d-none" routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
